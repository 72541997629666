<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/layout/header/header.vue"
import Footer from "@/components/layout/footer/Footer.vue"
export default {
  components:{
    Header,
    Footer
  }
}
</script>
<style lang="scss">
//nav {
//  padding: 30px;

// a {
// font-weight: bold;
//  color: #2c3e50;

//  &.router-link-exact-active {
//   color: #42b983;
//  }
// }
//}
</style>

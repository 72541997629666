<template>
    <footer>
        <div class="footer-wrapper">
            <div class="container">
                <div class="top-footer">
                    <button class="scrollBtn" @click="scrollToTop" title="Scroll To Top">
                        <span><font-awesome-icon icon="fa-solid fa-chevron-up" /></span>
                    </button>
                    <div class="hr-holder">
                        <hr>
                    </div>
                </div>
                <div class="bottom-footer">
                    <div class="row align-items-end">
                        <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <div class="logo-holder">
                                <img src="@/assets/coloredLogo.png" class="w-100" height="160" alt="logo-image">
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <h5>Let’s Talk</h5>
                            <div class="cursor-pointer">
                                <a href="tel:+201001111688">
                                    <span class="colored-icon">

                                        <font-awesome-icon icon="fa-solid fa-phone" />
                                    </span>
                                    <span>
                                        +201001111688
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <a href="mailto:Office@Joudegypt.Com">
                                <div class="cursor-pointer">
                                    <span class="colored-icon">

                                        <font-awesome-icon icon="fa-solid fa-envelope" />
                                    </span>
                                    <span>
                                        Office@Joudegypt.Com
                                    </span>
                                    <!-- <br>
                                    <span>
                                        <a href="http://www.joudegypt.com/" target="_blank">http://www.joudegypt.com/</a>
                                    </span> -->
                                    
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <div class="social">
                                <ul class="m-0 d-flex justify-content-end  align-items-center">
                                    <li>
                                        <a target="_blank" href="https://www.linkedin.com/company/joud-investment-co/" v-b-tooltip.hover title="Joud Investment-co">
                                            <font-awesome-icon icon="fa-brands fa-linkedin-in" />
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.instagram.com/joud_investment/" v-b-tooltip.hover title="joudinvestments">
                                            <font-awesome-icon icon="fa-brands fa-instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.facebook.com/joudEgypt" v-b-tooltip.hover title="@joudEgypt">
                                            <font-awesome-icon icon="fa-brands fa-facebook" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-2">
                                <div class="">
                                    <span class="colored-icon">

                                        <font-awesome-icon icon="fa-solid fa-location-dot" />
                                    </span>
                                    <span class="ml-5">
                                        Almaza Avenue Mall, Abou Bakr El Seddik St. Almaza, Heliopolis, Cairo.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hr-holder">
                        <hr>
                    </div>
                    <div class="text-center rights">
                        <p>© 2021 JOUD INVESTMENTS | All Rights Reseved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style scoped lang="scss">
footer {
    padding: 2% 0 0;
    background: #EDE6E1;
    color: #000;

    .scrollBtn {
        width: 50px;
        height: 50px;
        font-size: 25px;
        border-radius: 50%;
        border: 2px solid #5d5d5da6;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        color: #5d5d5da6;
        transition: all .3s ease-in-out;

        &:hover {
            border-color: #000;
            color: #000;
        }
    }

    .hr-holder {
        margin: 20px 0;
        width: 99%;

        hr {
            color:#7255452d;
            height: 1.5px !important;
            background:#72554526;
        }
    }

    .bottom-footer {
        padding: 30px 0 10px;

        a {
            color: #000 !important;
            text-decoration: none;
        }

        .logo-holder {
            img {

                object-fit: contain;
            }

            @media(max-width:992px) {
                width: 50%;
                margin: auto;
                object-fit: none;
            }
        }

        .colored-icon {
            color: #725545;
            font-size: 18px;
        }

        .social {
            ul {
                li {
                    width: 40px;
                    height: 40px;
                    border: 1px solid #967A69;
                    color: #604434 !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    margin-left: 10px;
                    cursor: pointer;

                    a {
                        color: #604434 !important;
                    }
                }
            }
        }

        .rights {
            color: #8E9BA5;
            transition: all .5s ease-in-out;

            &:hover {
                color: #000;
            }
        }
    }

}

@media(max-width:992px) {
    .align-items-end {
        align-items: center !important;
    }
}</style>
<template>
    <div class="wrapper header-wapper">


        <div>
            <header>
                <div class="container">
                    <div class="header-flex">

                        <router-link to="/" class="logo">
                            <img src="@/assets/images/logo.png" alt="logo-image" height="100"
                                srcset="@/assets/images/logo@2x.png">
                        </router-link>
                        <a class="call" href="tel:+201001111688" >
                            <div class="text-white cursor-pointer">
                                <font-awesome-icon icon="fa-solid fa-phone" />
                            </div>
                        </a>
                    </div>
                </div>
            </header>
            <b-carousel id="carousel-1" v-model="slide" :no-touch="false" :interval="4000" indicators background="#ababab"
                img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd">
                <!-- Slides with custom text -->
                <b-carousel-slide :img-src="require(`@/assets/images/slider/slider1.jpg`)" draggable="false">
                    <section class="banner" id="banner">
                        <!-- <div class="content">
                            <h2>GLOBAL</h2>
                            <h2>REACH</h2>
                        </div> -->
                    </section>
                </b-carousel-slide>
            </b-carousel>

        </div>



    </div>
</template>

<script>
export default {
    data() {
        return {
            slide: 0,
            sliding: null
        }
    },
    methods: {
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    header {

        overflow-x: hidden;
    }

    position: relative;

    p {
        font-weight: 300;
    }

    .banner {
        //position: relative;
        // width: 100%;
        // min-height: 100vh;
        //  display: flex;
        //  justify-content: center;
        //  align-items: center;
        //background: linear-gradient(rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url(https://i.postimg.cc/s29LNR86/bg.jpg);
        //  background-size: cover;
    }

    .banner .content {
        max-width: 900px;
        text-align: center;
    }

    .banner .content h2 {
        font-size: 5em;
        color: #fff;
        text-align: center;
        letter-spacing: 100px;
        opacity: 0.9;

        @media(max-width:992px) {
            letter-spacing: 20px;
        }
    }

    .banner .content p {
        font-size: 1em;
        color: #fff;
    }

    .btn {
        font-size: 1.5em;
        color: #fff;
        background: #ff0157;
        display: inline-block;
        padding: 10px 30px;
        text-transform: uppercase;
        margin-top: 20px;
        text-decoration: none;
        letter-spacing: 2px;
        transition: .5s;
    }

    .btn:hover {
        letter-spacing: 5px;
    }

    header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        transition: .5s;

        .header-flex {
            padding: 40px 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;

        }
    }

    .sticky {
        background: #fff;
        padding: 10px 100px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    }

    .sticky .logo {
        color: #111;
    }

    .sticky .navigation li a {
        color: #111;
    }

    header .logo {
        color: #fff;
        font-weight: bold;
        font-size: 2.5em;
        text-decoration: none;
    }

    header .logo span {
        color: #ff0157;
    }

    header .navigation {
        position: relative;
        display: flex;
    }

    header .navigation li {
        list-style: none;
        margin-left: 30px;
    }

    header .navigation li a {
        text-decoration: none;
        color: #fff;
        font-weight: 300;
        font-size: 1.5em;
        transition: .5s;
    }

    header .navigation li a:hover {
        color: #ff0157;
    }

    section {
        padding: 80px;
    }

    .row {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .row .col50 {
        position: relative;
        width: 48%;
    }






    @media (max-width: 991px) {

        header,
        header .sticky {
            padding: 10px 20px;
        }

        header .navigation {
            display: none;
        }

        header .navigation.active {
            width: 100%;
            height: calc(100% - 68px);
            position: fixed;
            top: 68px;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: #fff;
        }

        header .navigation li {
            margin-left: 0;
        }

        header .navigation li a {
            color: #111;
            font-size: 1.6em;
        }





        section {
            padding: 20px;
        }

        .banner .content h2 {
            font-size: 3em;
            color: #fff;
        }

        .row {
            flex-direction: column;
        }

        .row .col50 {
            position: relative;
            width: 100%;
        }

        .row .col50 .imgbx {
            height: 300px;
            margin-top: 20px;
        }

        .menu .content {
            margin-top: 20px;
        }

        .menu .content .box {
            margin: 10px;
        }

        .menu .content .box .imgbx {
            height: 260px;
        }

        .titeText {
            font-size: 1.8em;
            text-align: center;
        }

        .expert .content {
            margin-top: 20px;
        }

        .testimonial .content .box {
            margin: 10px;
            padding: 30px;
        }

        .expert .content .box {
            margin: 20px;
        }

        .contactform {
            padding: 40px 30px;
        }

        .sticky {
            padding: 10px 30px;
        }
    }

    .call {
        padding: 20px;
        border-radius: 50%;
        border: 2px solid #fff;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


}

.banner-with-button {
    h2 {
        font-size: 3rem;
    }

    button {
        border: 2px solid #fff;
        background: transparent;
        color: #fff;
        padding: 10px 2rem;
        margin-top: 30px;
    }

    h6 {
        text-transform: uppercase;
    }

    .arrow{
        padding: 0 10px;
    }
}
</style>